<template>
  <van-popup v-model="isPopupShow" :style="{ height: '45%' }" position="bottom">
    <van-tabs v-model="active" sticky @click="onClickTabs">
      <van-tab title="规格">
        <van-form>
          <van-cell-group inset>
            <van-cell-group inset>
              <van-field name="stepper" label="单位">
                <template #input>
                  <van-dropdown-menu style="width: 100%">
                    <van-dropdown-item v-model="skuObj.zUnitName" :options="zUnitNameOption"/>
                    <van-dropdown-item v-model="skuObj.sUnitName" :options="sUnitNameOption"/>
                  </van-dropdown-menu>
                </template>
              </van-field>
              <!-- 输入任意文本 -->
              <van-field v-model="skuObj.zUnitNum" type="digit" label="每件数量" @blur="calculateUnitNum"/>
              <van-field v-model="skuObj.zNum" type="digit" label="包装数" @blur="calculateUnitNum"/>
              <!-- 输入手机号，调起手机号键盘 -->
              <van-field v-model="skuObj.sNum" type="digit" label="散装数" @blur="calculateUnitNum"/>
              <van-field v-model="skuObj.price" type="number" label="价格" />
              <!-- 允许输入正整数，调起纯数字键盘 -->
              <van-field v-model="skuObj.number" type="number" label="总数">
                <template #button>
                  <van-button size="small" type="primary" @click="calculate4Total">反算</van-button>
                </template>
              </van-field>
            </van-cell-group>

          </van-cell-group>
          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit" @click="onClickButton">
              提交
            </van-button>
            <van-button round block type="danger" native-type="submit" style="margin-top: 5px" v-if="skuObj.status === 0" @click="onClickButtonDelete">
              删除
            </van-button>
          </div>
        </van-form>
      </van-tab>
      <van-tab title="收货">
        <van-form>
          <van-field
            readonly
            clickable
            name="calendar"
            :value="delivery.deliveryDate"
            label="日期"
            placeholder="点击选择日期"
            @click="showCalendar = true"
          />
          <van-calendar v-model="showCalendar" @confirm="onConfirmCalendar" />
          <van-field v-model="delivery.zNum" type="digit" label="整包数"/>
          <!-- 输入手机号，调起手机号键盘 -->
          <van-field v-model="delivery.sNum" type="digit" label="散装数"/>
          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit" @click="onClickButtonDelivery">
              提交
            </van-button>
          </div>
        </van-form>
      </van-tab>
    </van-tabs>
  </van-popup>
</template>

<script>
import {Calendar, Card, ContactList, DropdownItem, DropdownMenu, Field, Form, ShareSheet, Tab, Tabs} from 'vant';
import * as purchaseService from "@/api/admin/purchase";

export default {
  components: {
    [Card.name]: Card,
    [ContactList.name]: ContactList,
    [ShareSheet.name]: ShareSheet,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Form.name]: Form,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Calendar.name]: Calendar,
  },
  props: {
    pSkuObj: {
      type: Object
    }
  },
  data() {
    return {
      skuObj: {
        zUnitNum: 0,
        zNum: 0,
        sNum: 0,
        number: 0,
        sUnitName: '',
        zUnitName: '',
        price: 0
      },
      active: 0,
      zUnitNameOption: [
        {text: '箱', value: '箱'},
        {text: '件', value: '件'},
      ],
      zUnitNumOption: [
        {text: 20, value: 20},
        {text: 25, value: 25},
        {text: 30, value: 30},
      ],
      sUnitNameOption: [
        {text: '双', value: '双'},
        {text: '床', value: '床'},
      ],
      isPopupShow: false,
      showCalendar: false,
      delivery: {
        deliveryDate: '',
        zNum: 0,
        sNum: 0,
      }
    };
  },
  watch: {
    pSkuObj: {
      handler(newVal) {
        console.log('watch pSkuObj handler', newVal)
        if (newVal.zNum === undefined) {
          this.skuObj.zNum = newVal.number
        } else {
          this.skuObj.zNum = newVal.zNum
        }
        if (newVal.sNum === undefined) {
          this.skuObj.sNum = 0
        } else {
          this.skuObj.sNum = newVal.sNum
        }
        this.skuObj.zUnitNum = newVal.zUnitNum
        this.skuObj.number = this.skuObj.zUnitNum * this.skuObj.zNum + this.skuObj.sNum
        this.skuObj.zUnitName = newVal.zUnitName
        this.skuObj.sUnitName = newVal.sUnitName
        this.skuObj.productId = newVal.productId
        this.skuObj.sku = newVal.sku
        this.skuObj.price = newVal.price
        this.skuObj.uuid = newVal.uuid
        this.skuObj.productId = newVal.productId
        this.skuObj.sku = newVal.sku
        this.skuObj.purchasePlanUuid = newVal.purchasePlanUuid
        this.skuObj.status = newVal.status

        this.delivery.purchaseInfoUuid = newVal.uuid
        this.delivery.sNum = newVal.sNum
        this.delivery.zNum = newVal.zNum
        this.delivery.productId = newVal.productId
        this.delivery.sku = newVal.sku
      },
      immediate: true,
    }
  },
  created() {
  },
  methods: {
    calculateUnitNum() {
      console.log('purchaseEditPopup calculateUnitNum', this.skuObj)
      if (this.skuObj.zUnitNum === null || Number(this.skuObj.zUnitNum) <= 0) {
        this.$toast('请填写有效每件数量')
        return
      }
      if (Number(this.skuObj.sNum) > Number(this.skuObj.zUnitNum)) {
        this.$toast('散装数不能大于包装数')
        return
      }
      console.log('purchaseEditPopup operation 1', this.skuObj.zUnitNum)
      // 操作整包数或者散装数
      const number = Number(this.skuObj.zUnitNum) * Number(this.skuObj.zNum) + Number(this.skuObj.sNum)
      console.log('purchaseEditPopup operation 2', number)
      this.skuObj.number = number
    },
    calculate4Total() {
      // 操作总数
      // 修改总数
      if (this.skuObj.zUnitNum === null || Number(this.skuObj.zUnitNum) <= 0) {
        this.$toast('包装数不合法');
        return
      }
      const zNumber = parseInt(Number(this.skuObj.number) / Number(this.skuObj.zUnitNum))
      const sNumber = Number(this.skuObj.number) % Number(this.skuObj.zUnitNum)
      this.skuObj.zNum = zNumber
      this.skuObj.sNum = sNumber
    },
    onClickButton() {
      console.log('purchaseEditPopup 修改商品规格提交', this.skuObj)
      this.isPopupShow = false
      this.$emit('editSku', this.skuObj)
    },
    onClickButtonDelete() {
      const data = {
        productId: this.skuObj.productId,
        purchasePlanUuid: this.skuObj.purchasePlanUuid,
        sku: this.skuObj.sku
      }
      console.log('onClickButtonDelete', data)
      // this.$emit('deletedData', data)
      purchaseService.deleteP(data).then(res => {
        if (res.status === 200) {
          this.$emit('deletedData', data)
          this.$toast("删除成功")
          this.isPopupShow = false
        }
      })
    },
    onConfirmCalendar(date) {
      console.log('onClickButtonDelivery date', date)
      this.delivery.deliveryDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      this.showCalendar = false
    },
    formatCalendarDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    onClickButtonDelivery() {
      this.isPopupShow = false
      console.log('onClickButtonDelivery', this.delivery)
      purchaseService.addDelivery(this.delivery).then(res => {
        if (res.status === 200) {
          this.$emit('skuDeliveryData', this.delivery)
          this.$toast("提交成功")
        }
      })
    },
    onClickTabs(name, title) {
      if (name === 1) {
        this.delivery.deliveryDate = this.formatCalendarDate(new Date())
      }
    },
  }
};
</script>

<style lang="less">
.stock {
  font-size: 20px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #0bb20c;
}

.stockWarn {
  color: red;
}

.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>

<template>
  <van-popup v-model="isPaymentShow" position="bottom" :style="{ height: '40%' }">
    <van-notice-bar
      wrapable
      :scrollable="false"
      color="#1989fa" background="#ecf9ff" left-icon="info-o">
      {{ payData.text }}
    </van-notice-bar>
    <slot></slot>
    <van-form @submit="onClickSubmit">
      <van-cell-group inset>
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="payData.payDateStr"
          label="时间选择"
          placeholder="点击选择时间"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-datetime-picker
            v-model="payData.payDate"
            type="date"
            title="选择日期"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <van-field name="radio" label="支付方式">
          <template #input>
            <van-radio-group v-model="payData.payType" direction="horizontal">
              <van-radio name="微信" style="margin-top: 2px;">微信</van-radio>
              <van-radio name="现金" style="margin-top: 2px;">现金</van-radio>
              <van-radio name="支付宝" style="margin-top: 2px;">支付宝</van-radio>
              <van-radio name="银行卡" style="margin-top: 2px;">银行卡</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field v-model="payData.payPrice"
                   size="small" label="支付金额" rows="1" type="number" style="margin-bottom: 3px;"/>
        <van-field
            v-model="payData.mark"
            rows="2"
            autosize
            label="留言"
            type="textarea"
            maxlength="50"
            placeholder="请输入留言"
            show-word-limit
        />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </van-popup>
</template>

<script>
import {CouponCell, DatetimePicker, Field, Form, NavBar, NoticeBar, Notify, Radio, RadioGroup, Toast} from 'vant';

export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Notify.name]: Notify,
    [CouponCell.name]: CouponCell,
    [NavBar.name]: NavBar,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [NoticeBar.name]: NoticeBar,
    [DatetimePicker.name]: DatetimePicker,
  },
  props: {
    payData: {
      type: Object
    }
  },
  data() {
    return {
      verifySuccess: true,
      isPaymentShow: false,
      minDate: new Date(2020, 0),
      maxDate: new Date(2025, 10),
      showPicker: false,
    };
  },
  methods: {
    onConfirm(time) {
      console.log(time)
      this.payData.payDateStr = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
      this.payData.payDate = time;
      this.showPicker = false;
    },
    verifyPayment() {
      // 如果支付状态为付清 则支付金额必须等于订单金额
      // 如果支付状态为欠账 则支付金额必须小于订单金额
      // 选择付清则自动设置全额为支付金额
      // 选择支付状态为结账时则设置支付金额为订单金额
      if (Number(this.payData.payPrice) <= Number(0)) {
        Toast("请输入支付金额")
        this.verifySuccess = false
        return
      }
      this.verifySuccess = true
    },
    onClickSubmit() {
      this.verifyPayment()
      if (!this.verifySuccess) {
        return
      }
      this.$emit('payData', this.payData)
    },
  },
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>

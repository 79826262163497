<template>
  <van-popup v-model="isPopupShow" :style="{ height: '45%' }" position="bottom" @close="onClose">
    <van-steps direction="vertical" :active="0">
      <van-step v-for="item in purchaseDatas" :key="item.createTime" :name="item.createTime">
        <div>{{ item.createTime }}</div>
        <div style="margin-left: 15px;">{{ item.storeName }}</div>
        <van-row>
          <van-col span="20" style="height: 26.5px; line-height: 26.5px">
                <span style="display: inline-block; vertical-align: middle;"><span>收:{{ item.deliveryNum }}件</span> 付:{{ item.payPrice }} 方式:{{ item.payType }}
                </span>
          </van-col>
          <van-col span="4">
            <van-button type="danger" size="mini" @click="onClickDelete(item.uuid)">删除</van-button>
          </van-col>
        </van-row>
      </van-step>
    </van-steps>
  </van-popup>
</template>

<script>
import {Popup, Step, Steps} from 'vant';
import * as purchaseService from "@/api/admin/purchase";

export default {
  components: {
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Popup.name]: Popup,
  },
  data() {
    return {
      active: 0,
      isPopupShow: false,
      purchaseDatas: []
    };
  },
  watch: {},
  created() {
  },
  methods: {
    loadData(purchasePlanUuid) {
      purchaseService.getCarriage(purchasePlanUuid).then(res => {
        if (res.status === 200) {
          this.purchaseDatas = res.data
        }
      })
    },
    onClickDelete(uuid) {
      purchaseService.deleteCarriage(uuid).then(res => {
        if (res.status === 200) {
          this.purchaseDatas = this.purchaseDatas.filter(i => i.uuid !== uuid)
          this.$toast("删除成功")
        }
      })
    },
    onClose() {
      this.$emit('delete')
      this.purchaseDatas = []
    }
  }
};
</script>

<style lang="less">
.stock {
  font-size: 20px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #0bb20c;
}

.stockWarn {
  color: red;
}

.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>

<template>
  <div>
    <van-nav-bar
      :title="navBarTitle"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>
    <van-form validate-first @failed="onFailed">
      <!-- 通过 pattern 进行正则校验 -->
      <van-field
        v-model="orderUser.username"
        label="厂家"
        name="pattern"
        placeholder="请选择厂家"
        readonly
      >
        <template #button>
          <van-button size="small" type="primary" @click="showPopup">选择厂家</van-button>
        </template>
      </van-field>
      <!-- 通过 validator 进行函数校验 -->
      <van-field
        v-model="orderUser.linkman"
        label="联系人"
        name="validator"
        placeholder="请选择厂家"
        readonly
      />
      <van-field
        v-model="orderUser.phone"
        label="手机号码"
        name="validator"
        placeholder="请选择厂家"
        readonly
      />

      <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      >
        商品信息
      </van-divider>

      <div ref="container" style="padding-bottom: 35px">
        <van-sticky :container="container" :offset-top="50">
          <van-button type="primary" style="margin-left: 15px; margin-bottom: 10px;" @click="showProductPopup()">选择商品</van-button>
          <van-button type="primary" size="small" style="margin-left: 15px; margin-bottom: 10px;" v-if="editData.plan !== undefined && editData.plan.status === 0 && selectedProducts.length > 0" @click="onClickPlace()">下单</van-button>
          <van-button type="danger" size="small" style="margin-left: 15px; margin-bottom: 10px;" v-if="editData.plan !== undefined && editData.plan.status === 0" @click="onClickDelete()">删除</van-button>
          <van-popover
            v-if="purchasePlanUuid !== null"
            v-model="showPopover"
            trigger="click"
            :actions="actions"
            @select="onSelect"
            style="margin-left: 15px;"
          >
            <template #reference>
              <van-button type="info" size="mini">更多操作</van-button>
            </template>
          </van-popover>
        </van-sticky>
        <van-notice-bar v-if="editData.payTotal !== undefined" color="#1989fa" background="#ecf9ff" left-icon="info-o" @click="onClickShowPay">
          已付货款：{{ editData.payTotal}}
        </van-notice-bar>
        <van-notice-bar v-if="editData.carriage !== undefined" color="#1989fa" background="#ecf9ff" left-icon="info-o" @click="onClickCarriage">
          运费为：{{ editData.carriage}}
        </van-notice-bar>
        <GeneralPayPopup  ref="generalPayPopup"
                          :payData="payData"
                          @payData="onEmitPayData">
          <van-field v-if="isPurchaseCarriage"
                     v-model="deliveryNum" type="number" label="收货数量（件）" />
        </GeneralPayPopup>

        <div style="padding-left: 8px; padding-right: 8px; margin-top: 5px; margin-bottom: 5px;"
             v-for="item in selectedProducts" :key="item.id" :name="item.id">
          <purchaseProductInfo
            :product="item"
            @infoEditSku="onSkuGridEdit"
            @deletedData="onLoadData"
            @placeData="onLoadData"
            @infoForDelivery="onClickPurchaseSkuDelivery"
            @skuDeliveryData="onSkuDeliveryData"
            @onRefresh="onLoadData"
          ></purchaseProductInfo>
        </div>
      </div>

      <div style="margin: 16px;">
        <van-submit-bar :price="amount" button-text="提交" :disabled="submitDisabled" @submit="onShowPaymentBeforeSubmit">
          <template #default>
            {{ zTotal }}件 {{ sTotal }}零
          </template>
        </van-submit-bar>
      </div>
    </van-form>
    <purchaseDelivery ref="purchaseDelivery"></purchaseDelivery>
    <PurchaseCarriage @delete="onDeleteCarriage" ref="purchaseCarriage"></PurchaseCarriage>
    <PurchaseShowPay @delete="onDeletePay" ref="purchaseShowPay"></PurchaseShowPay>
  </div>
</template>

<script>
import {
  Cascader,
  CouponCell,
  DatetimePicker,
  Dialog,
  Divider,
  Field,
  Form,
  NavBar,
  NoticeBar,
  Notify,
  Popover,
  Popup,
  Radio,
  RadioGroup,
  Stepper,
  Sticky,
  SubmitBar,
  Tag
} from 'vant';

import * as purchaseService from "@/api/admin/purchase";
import {mapState} from 'vuex'
import $store from "@/store";
import purchaseProductInfo from "@/components/purchase/purchaseProductInfo.vue";
import PurchaseDelivery from "@/components/purchase/purchaseDelivery.vue";
import GeneralPayPopup from "@/components/generalPayPopup.vue";
import PurchaseCarriage from "@/components/purchase/purchaseCarriage.vue";
import PurchaseShowPay from "@/components/purchase/purchaseShowPay.vue";

export default {
  components: {
    GeneralPayPopup,
    PurchaseDelivery,
    purchaseProductInfo,
    PurchaseCarriage,
    PurchaseShowPay,
    [Form.name]: Form,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Stepper.name]: Stepper,
    [SubmitBar.name]: SubmitBar,
    [Divider.name]: Divider,
    [DatetimePicker.name]: DatetimePicker,
    [Sticky.name]: Sticky,
    [Cascader.name]: Cascader,
    [CouponCell.name]: CouponCell,
    [NavBar.name]: NavBar,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Tag.name]: Tag,
    [Popover.name]: Popover,
    [NoticeBar.name]: NoticeBar,
  },
  data() {
    return {
      amount: 0,
      zTotal: null,
      sTotal: null,
      container: null,
      purchasePlanUuid: null,
      purchasePlan: {},
      submitDisabled: false,
      isSelectNew: false,
      navBarTitle: '新增进货计划',
      editData: {},
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [{ text: '付货款' }, { text: '付运费' }, { text: '完成' }],
      deliveryNum: '',
      payData: {},
      isPurchaseCarriage: false
    };
  },
  computed: {
    ...mapState({
    }),
    // 箭头函数可使代码更简练
    // 传字符串参数 'count' 等同于 `state => state.count`
    // countAlias: 'count',
    orderUser: {
      get() {
        return this.$store.state.order.orderUser
      },
      set(v) {
        this.$store.state.order.orderUser = v
      }
    },
    selectedProducts: {
      get() {
        const data  = this.$store.state.order.mergeProducts
        console.log('selectedProducts  get', data)
        data.forEach(item => {
          item.storeProductAttrValues.forEach(subItem => {
            // 判断是否有新选择的商品
            if (subItem.uuid ===  undefined || subItem.uuid === '' || subItem.uuid === null) {
              this.isSelectNew = true
              this.submitDisabled = false
            }
          })
        })
        return data
      },
      set(v) {
        this.$store.state.order.mergeProducts = v
      }
    },
  },
  watch: {
    selectedProducts: {
      handler(newVal) {
        console.log('selectedProducts watch ooo', this.selectedProducts)
        console.log('selectedProducts', newVal)
        let am = 0
        newVal.forEach(item => {
          if (item.keyword !== undefined) {
            item.storeName = item.keyword
          }
          item.storeProductAttrValues.forEach(skuItem => {
            if (item.keyword !== undefined) {
              skuItem.storeName = item.keyword
            }
            let subAmount;
            if (skuItem.sNum === undefined) {
               subAmount = (Number(skuItem.zNum) * Number(skuItem.zUnitNum)) * Number(skuItem.cost)
            } else {
               subAmount = Number(Number(skuItem.zNum) * Number(skuItem.zUnitNum) + Number(skuItem.sNum)) * Number(skuItem.price)
            }
            am += subAmount;
          })
        })
        this.amount = am * 100
        this.assignmentAfterSelect()
      },
      immediate: true,
    }
  },
  mounted() {
    this.container = this.$refs.container;
  },
  created() {
    this.purchasePlanUuid = this.$route.query.purchasePlanUuid
    if (this.purchasePlanUuid !== null && this.purchasePlanUuid !== '' && this.purchasePlanUuid !== undefined) {
      this.navBarTitle = '进货计划详情'
      this.submitDisabled = true
      this.onLoadData()
    }
  },
  beforeDestroy() {
  },
  methods: {
    onSelect(action) {
      if (action.text === '付货款') {
        this.payData = {
          factoryUuid: this.orderUser.uuid,
          purchasePlanUuid: this.purchasePlanUuid,
          text: '向' + this.orderUser.username + '支付货款',
          payType: '微信',
          payPrice: this.amount / 100,
          operateType: action.text,
          payDate: new Date(),
          payDateStr: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
        }
        this.$refs.generalPayPopup.isPaymentShow = true
      } else if (action.text === '付运费') {
        this.isPurchaseCarriage = true
        this.payData = {
          factoryUuid: this.orderUser.uuid,
          purchasePlanUuid: this.purchasePlanUuid,
          text: '向' + this.purchasePlanUuid + '订货计划支付运费',
          payType: '微信',
          operateType: action.text,
          payDate: new Date(),
          payDateStr: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
        }
        this.$refs.generalPayPopup.isPaymentShow = true
      } else if (action.text === '完成') {
        this.onComplete()
      }
    },
    onComplete() {
      let data = {
        purchasePlanUuid: this.purchasePlanUuid,
      }
      purchaseService.onComplete(data).then(res => {
        if (res.status === 200) {
          this.$toast('提交成功')
          this.onLoadData()
        }
      })
    },
    onEmitPayData(data) {
      console.log('onEmitPayData', data)
      if (data.operateType === '付货款') {
        purchaseService.factoryPay(data).then(res => {
          if (res.status === 200) {
            this.$toast('提交成功')
            this.$refs.generalPayPopup.isPaymentShow = false
            this.onLoadData()
          }
        })
      } else if (data.operateType === '付运费') {
        data.deliveryNum = this.deliveryNum
        purchaseService.payCarriage(data).then(res => {
          if (res.status === 200) {
            this.isPurchaseCarriage = false
            this.$toast('提交成功')
            this.$refs.generalPayPopup.isPaymentShow = false
            this.onLoadData()
          }
        })
      }
    },
    onLoadData() {
      purchaseService.getPurchasePlan(this.purchasePlanUuid).then(res => {
        if (res.status === 200) {
          this.editData = res.data
          console.log('index 加载数据结果为：', this.editData)
          this.orderUser = {
            username: res.data.plan.factoryName,
            linkman: res.data.plan.linkman,
            phone: res.data.plan.tel,
            uuid: res.data.plan.factoryUuid
          }
          if (res.data.purchaseProducts !== undefined) {
            let union = [...this.selectedProducts, ...res.data.purchaseProducts]
            console.log('union', union)
            for (let i = 0, len = res.data.purchaseProducts.length; i < len; i++ ) {
              for (let j = 0, length = this.selectedProducts.length; j < length; j++) {
                if (res.data.purchaseProducts[i].productId === this.selectedProducts[j].productId) {
                  union.splice(union.findIndex(item => item.productId === res.data.purchaseProducts[i].productId), 1)
                }
              }
            }
            this.selectedProducts = union
          }
        }
      })
    },
    async purchaseEditSku(data) {
      await this.assignmentSku(data)
      await this.calculate4Sku()
      await this.calculate4Product()
    },
    async assignmentAfterSelect() {
      await this.calculate4Sku()
      await this.calculate4Product()
      this.onChangeAmount()
    },
    assignmentSku(data) {
      this.selectedProducts.forEach(item => {
        if (item.id === data.productId) {
          item.storeProductAttrValues.forEach(subItem => {
            if (subItem.sku === data.sku) {
              subItem.zUnitNum = data.zUnitNum
              subItem.zNum = data.zNum
              subItem.sNum = data.sNum
              subItem.number = data.number
              subItem.zUnitName = data.zUnitName
              subItem.sUnitName = data.sUnitName
              subItem.price = data.price
            }
          })
        }
      })
    },
    calculate4Sku() {
      console.log('算商品 规格维度开始')
      // 算每个商品的整包数与散包数
      this.selectedProducts.forEach(item => {
        let sNumber = 0;
        let zNumber = 0;
        item.storeProductAttrValues.forEach(subItem => {
          if (subItem.sNum !== undefined) {
            sNumber += Number(subItem.sNum)
            subItem.number = Number(subItem.zNum) * Number(subItem.zUnitNum) + Number(subItem.sNum)
          } else {
            subItem.number = Number(subItem.zNum) * Number(subItem.zUnitNum)
          }
          if (subItem.zNum !== undefined) {
            zNumber += Number(subItem.zNum)
          }
        })
        item.sNum = sNumber
        item.zNum = zNumber
      })
      console.log('算商品 规格维度结束', this.selectedProducts)
    },
    calculate4Product() {
      console.log('算商品 商品维度开始')
      // 算所有商品的整包数与散包数
      let sNumber = 0;
      let zNumber = 0;
      this.selectedProducts.forEach(item => {
        if (item.sNum !== undefined) {
          sNumber += Number(item.sNum)
        }
        if (item.zNum !== undefined) {
          zNumber += Number(item.zNum)
        }
      })
      this.zTotal = zNumber
      this.sTotal = sNumber
      console.log('算商品 商品维度结束', this.selectedProducts)
    },
    onSkuGridEdit(data) {
      console.log('index 接受商品规格修改事件', data)
      console.log('index 所有商品：', this.selectedProducts)
      this.assignmentAfterSelect()
      if (data.uuid !== undefined && data.uuid !== '' && data.uuid !== null) {
        purchaseService.editSku(data).then(res => {
          if (res.status === 200) {
            this.$toast("修改成功")
          }
        })
      }
    },
    onClickLeft() {
      this.selectedProducts = []
      this.$router.push({path: '/purchase/list'})
    },
    toHome() {
      this.selectedProducts = []
      this.$router.push({path: '/index'})
    },
    onDeleteProductAttr(productAttrUnique) {
      for (let j = 0; j < this.selectedProduct.storeProductAttrValues.length; j++) {
        if (this.selectedProduct.storeProductAttrValues[j].productAttrUnique === productAttrUnique) {
          this.selectedProduct.storeProductAttrValues.splice(j, 1);
        }
      }
      for (let j = 0; j < this.selectedProducts.length; j++) {
        console.log(this.selectedProducts[j].storeProductAttrValues.length)
        if (this.selectedProducts[j].storeProductAttrValues === undefined || this.selectedProducts[j].storeProductAttrValues.length === 0) {
          this.selectedProducts.splice(j, 1);
        }
      }
      this.onChangeAmount()
    },
    onChangeAmount() {
      console.log('算商品 onChangeAmount 计算所有商品总价格', this.selectedProducts)
      let amount = 0;
      let number = 0;
      this.selectedProducts.forEach(item => {
        let subAmount = 0;
        let subNumber = 0;
        item.storeProductAttrValues.forEach(subItem => {
          subAmount += subItem.price * subItem.number;
          subNumber += subItem.number;
        })
        item.totalNum = subNumber
        item.totalPrice = subAmount
        amount += subAmount;
        number += subNumber;
      })
      this.amount = amount * 100;
      console.log('算商品 onChangeAmount 计算所有商品总价格结束', this.amount)
    },
    onShowPaymentBeforeSubmit() {
      this.onSubmitForOrder()
    },
    resetData() {
      console.log('resetData')
      this.sTotal = null
      this.zTotal = null
      this.amount = 0
      this.selectedProducts = []
      $store.commit("SET_PRODUCTS", [])
      this.orderUser = {}
    },
    onSubmitForOrder() {
      console.log('提交前再计算一遍 商品规格维度 onSubmitForOrder')
      this.calculate4Sku()
      let accountOrderInfos = []
      this.selectedProducts.forEach(item => {
        item.storeProductAttrValues.forEach(tt => {
          accountOrderInfos.push(tt)
        })
      })
      if (accountOrderInfos.length < 1) {
        this.$toast("商品不能为空")
        return
      }
      let data = {
        factoryUuid: this.orderUser.uuid,
        factoryName: this.orderUser.username,
        linkman: this.orderUser.linkman,
        tel: this.orderUser.phone,
        purchasePlanUuid: this.purchasePlanUuid,
        accountPurchaseInfoDtos: accountOrderInfos
      }
      console.log('onSubmitForOrder orderUser', this.orderUser)
      console.log('onSubmitForOrder data', data)

      if (data.factoryName === undefined) {
        this.$toast("请选择厂家")
        return
      }
      Dialog.confirm({
        title: '确认修改此订单吗？',
        message: '请仔细检查～',
      })
        .then(() => {
          purchaseService.addPlan(data).then(res => {
            this.resetData()
            Notify({type: 'success', message: '新增成功'});
          }).thenReject(res => {
            console.log(res)
            Notify({type: 'danger', message: res});
          }).fail(res => {
            console.log('fail')
          }).catch(res => {
            console.log('catch')
          })
        })
        .catch(() => {
          // on cancel
        });
    },
    showProductPopup() {
      let selectProductAfter = {
        redirect: this.$router.currentRoute.fullPath,
        type: 'purchase',
        userId: this.orderUser.uuid
      }
      $store.commit('setSelectProductAfter', selectProductAfter)
      this.$router.push({path: '/order/product/' + this.orderUser.id})
    },
    showPopup() {
      let selectEntityAfter = {
        redirect: this.$router.currentRoute.fullPath,
        type: 'purchase',
      }
      $store.commit('setSelectEntityAfter', selectEntityAfter)
      this.$router.push({path: '/order/user'})
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
    onClickPlace() {
      const data = {
        purchasePlanUuid: this.data.plan.uuid
      }
      purchaseService.placeP(data).then(res => {
        if (res.status === 200) {
          this.onLoadData()
          this.$toast("下单成功")
        }
      })
    },
    onClickDelete() {
      const data = {
        purchasePlanUuid: this.data.plan.uuid
      }
      purchaseService.deleteP(data).then(res => {
        if (res.status === 200) {
          this.onLoadData()
          this.$toast("删除成功")
        }
      })
    },
    onClickPurchaseSkuDelivery(data) {
      const postData = {
        purchasePlanUuid: data.purchasePlanUuid,
        productId: data.productId,
        sku: data.sku
      }
      this.$refs.purchaseDelivery.loadData(postData)
      this.$refs.purchaseDelivery.isPopupShow = true
    },
    onSkuDeliveryData(data) {
      console.log('index 接收到商品收货事件', data)
      this.onLoadData()
    },
    onClickCarriage() {
      this.$refs.purchaseCarriage.loadData(this.purchasePlanUuid)
      this.$refs.purchaseCarriage.isPopupShow = true
    },
    onDeleteCarriage() {
      this.onLoadData()
    },
    onClickShowPay() {
      this.$refs.purchaseShowPay.loadData(this.purchasePlanUuid)
      this.$refs.purchaseShowPay.isPopupShow = true
    },
    onDeletePay() {
      this.onLoadData()
    }
  },
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
